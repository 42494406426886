// ########## FONTS #########
// -> font poppins
@font-face {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/poppins.woff') format('woff'),
    url('../fonts/poppins.woff2') format('woff2'),
}

// ########## GENERAL #########
* {
    font-family: 'poppins' !important;
}

html, body{
    background: #ecf6ff;
    padding: 0;
    margin: 0;
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #000; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #000; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #000; 
    }
}

a{
    text-decoration: none;
}

// ############ Info boxes  ##############
.error{
    border-bottom: 2px solid red !important;
    border-radius: 5px;
}

.success-message{
    text-align: center;
    font-weight: bold;
    color: green;
    background-color: rgba(206, 248, 206, .3);
    padding: 10px;
    border-radius: 5px;
}

.info-message{
    text-align: center;
    font-weight: bold;
    color: #2e77ae;
    background-color: rgba(46, 119, 174, .1);
    padding: 10px;
    border-radius: 5px;
}

.warning-message{
    text-align: center;
    font-weight: bold;
    color: rgb(213, 138, 0);
    background-color: rgba(255, 165, 0, .1);
    padding: 10px;
    border-radius: 5px;
}

.error-message{
    text-align: center;
    font-weight: bold;
    color: brown;
    background-color: rgba(165, 42, 42, .1);
    padding: 10px;
    border-radius: 5px;
}

.success-box{
    border-left: 5px solid green;
    display: flex;
    justify-content: center;
    align-items: center !important;
    text-align: center;
    font-weight: bold;
    color: green;
    background-color: rgba(206, 248, 206, .3);
    padding: 10px;
    border-radius: 5px;
}

.info-box{
    border-left: 5px solid #2e77ae;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: #2e77ae;
    background-color: rgba(46, 119, 174, .1);
    padding: 10px;
    border-radius: 5px;
}

.warning-box{
    border-left: 5px solid rgb(213, 138, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: rgb(213, 138, 0);
    background-color: rgba(255, 165, 0, .1);
    padding: 10px;
    border-radius: 5px;
}

.error-box{
    border-left: 5px solid brown;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: brown;
    background-color: rgba(165, 42, 42, .1);
    padding: 10px;
    border-radius: 5px;
}

.error-text {
    color: red !important;
    font-size: .8em;
}

// ############ Neomophism ##############
.neo {
    border-radius: 10px !important;
    justify-content: center !important;
    transition: all ease-in-out 0.3s !important;
}

.neo-bulge {
    box-shadow:
        12px 12px 16px 0 rgb(23, 35, 60, .1) inset,
        -8px -8px 24px 0 rgb(23, 35, 60, .1) inset !important;
}

.neo-down {
    box-shadow:
        inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
        inset -6px -6px 10px 0 rgba(255, 255, 255, 0.5) !important;
}

.neo-up {
    box-shadow: 3px 3px 14px 0 rgb(23, 35, 60, .2),
        -8px -8px 9px 0 rgba(255, 255, 255, 0.55) !important;
}

// ############ Button ##############
button{
    box-shadow: none !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
}
.app-btn{
    box-shadow: none !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
}

// ############ Youtube embed ##############
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
    width: 100%;
  position: absolute;
}

@media(max-width: 768px){
    .video-responsive iframe {
    height: 100%;
    width: 100%;
    left: 0;
  top: 0;
  height: 100%;
    width: 100%;
  position: absolute;
}
}

